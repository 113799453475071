import domReady from '@roots/sage/client/dom-ready';
import '../styles/app.scss';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

Swiper.use([Autoplay]);

// import {Common} from './routes/common';


/**
 * Application entrypoint
 */
domReady(async () => {
  // clone the logos to fill the screen with them
  const preloader = document.querySelector('.preloader');

  if(preloader) {
    setTimeout(() => {
      preloader.classList.add('preloader--hidden')
    }, 500)
  }

  const logos = document.querySelectorAll('.swiper--top .swiper-slide');
  if(logos.length > 0) {
    const logoContainer = document.querySelector('.swiper--top .swiper-wrapper');
    const logoContainerWidth = logoContainer.offsetWidth;
    const logoWidth = logos[0].offsetWidth;
    const logoCount = Math.ceil(logoContainerWidth / logoWidth) + 1;

    for (let i = 0; i < logoCount; i++) {
      logos.forEach((logo) => {
        const clone = logo.cloneNode(true);
        logoContainer.appendChild(clone);
      });
    }

    const swiperTime = document.querySelector('.swiper--top').dataset.time;
    new Swiper('.swiper--top', {
      // Optional parameters
      // slidesPerView: 5,
      spaceBetween: 24,
      centeredSlides: true,
      speed: (swiperTime || 4000) * 1,
      autoplay: {
        delay: 0,
      },
      loop: true,
      slidesPerView: 'auto',
      allowTouchMove: false,
      disableOnInteraction: true,
    });
  }

  const toggle_menu = document.querySelector('#toggle_menu');
  if(toggle_menu) {
    toggle_menu.addEventListener('click', function() {
      const menu = document.querySelector('.mega_menu');
      menu.classList.toggle('active');
      toggle_menu.classList.toggle('active');

      document.querySelector('html').classList.toggle('clipped');
    });
  }


  const toggle_content = document.querySelectorAll('.toggle_content');
  if(toggle_content.length > 0) {
    toggle_content.forEach((toggle) => {
      const show = toggle.querySelector('.toggle_content__show');
      const hide = toggle.querySelector('.toggle_content__hide');
      // const content_1 = toggle.querySelector('.toggle_content--1');
      const content_2 = toggle.querySelector('.toggle_content--2');

      if(show) {
        show.addEventListener('click', function(e) {
          e.preventDefault();
          if(!content_2) return
          toggle.classList.add('active');
        });
      }

      if(hide) {
        hide.addEventListener('click', function(e) {
          e.preventDefault();
          if(!content_2) return
          toggle.classList.remove('active');
        });
      }
    });
  }

  const scrollTo = document.querySelectorAll('[data-scroll-to]');
  if(scrollTo.length > 0) {
    scrollTo.forEach((element) => {
      element.addEventListener('click', function(e) {
        e.preventDefault();
        const target = document.querySelector(element.getAttribute('data-scroll-to'));
        if(!target) return;
        target.scrollIntoView({ behavior: 'smooth' });
      });
    });
  }

  const inputsCheckbox = document.querySelectorAll('[type="checkbox"]');
  if(inputsCheckbox.length > 0) {
    inputsCheckbox.forEach((input) => {
      const span = document.createElement('span');
      span.classList.add('fake_checkbox');
      // add span after input
      input.parentNode.insertBefore(span, input.nextSibling);
    });
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

